<style scoped>
.modalItem {
  min-height: 400px;
  overflow: scroll;
  margin-top: 3px;
  padding: 6px 10px;
  margin: 5px 0;
  text-align: center;
  box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
}
.chooseDeptFormItem {
  padding: 6px 10px;
  box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
  display: flex;
  justify-content: space-between;
  min-height: 30px;
  line-height: 30px;
}
.chooseDeptFormItem .chooseDeptFormItemTitle {
  font-weight: bold;
  color: #2d4761;
  flex: 1;
}
.chooseDeptFormItem .detail {
  flex: 1;
}
</style>
<template>
  <div v-if="showModal">
    <Modal
      width="800px"
      v-model="showChooseDept"
      @onCancel="cancel"
      @onOk="chooseDept"
      fullscreen
      :closable="false"
    >
      <p slot="header" style="text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span> 选择 </span>
      </p>

      <div>
        <div class="chooseDeptFormItem">
          <div class="chooseDeptFormItemTitle">开始日期</div>
          <div class="detail">
            <DatePicker
              format="yyyy-MM-dd"
              type="date"
              :clearable="false"
              :option="option"
              :value="pickerValue"
              @on-change="setStartDateValue"
              placeholder="请选择时间"
              width="200px"
            ></DatePicker>
          </div>
        </div>

        <div class="chooseDeptFormItem">
          <div class="chooseDeptFormItemTitle">结束日期</div>
          <div class="detail">
            <DatePicker
              format="yyyy-MM-dd"
              type="date"
              :clearable="false"
              :option="option"
              :value="pickerEndValue"
              @on-change="setEndDateValue"
              placeholder="请选择时间"
              width="200px"
            ></DatePicker>
          </div>
        </div>
      </div>
      <loading-cmp v-if="loading"></loading-cmp>

      <!-- <div>
        <search-top
          :needEmit="true"
          @search="searchCon"
          title="检索"
        ></search-top>
        <rn-content-min
          @chooseItem="chooseItem"
          :listCon="listCon"
          :curDataLength="listCon.length"
          :dataTotal="dataTotal"
          :refreshDataStatus="refreshDataStatus"
        ></rn-content-min>
        <load-more
          @refresh="onRefresh"
          :load-status="isLoadMore"
          :isShow="loadMoreShow"
        ></load-more>
      </div> -->

      <div slot="footer" style="text-align: center">
        <Button type="info" size="large" @click="chooseDept">确定</Button>
        <Button type="default" size="large" @click="cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import _ from "lodash";
import loadMore from "./loadMoreData.vue";
import rnContentMin from "./rnContentFiveContent.vue";
import loadingCmp from "../components/loadingCmp.vue";
import searchTop from "./searchTopContent.vue";

import { wxConPost, getUserPm } from "../api/httpApi";
import moment from "moment";

let curDate = new Date();

export default {
  name: "deptChooseCom",
  use: ["gtttUserMgt$userPm"],

  data() {
    return {
      refreshDataStatus: false,
      loadMoreShow: false,
      isLoadMore: true,

      viewName: "name1",
      selectColumnValue: "",
      optionColumnValue: "",
      searchValue: "",

      showSuper: false, // 超管显示部门
      dataFrom: "单位",
      // deptList: [], // 可选部门
      // deptIdArr: [], // 选择部门Id
      // deptNameArr: [], // 选择部门名字

      childDept: [], // 全部子部门
      curDept: {},
      chooseZd: true,
      showChooseDept: false,

      // 选择时间
      pickerValue: new Date(curDate.getTime() - 7 * 24 * 60 * 60 * 1000),
      pickerEndValue: curDate,
      startDate: "",
      endDate: "",
      option: {},

      listCon: [], // 当前页的任务数据
      dataTotal: 1, // 数据总条数
      isSearch: false,

      loading: false,

      searchContent: "",
      postUrl: "/services/gttt-wxstats-mgt/content/sliceContentList",
      postBody: {},
      cidArr: [],
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    // 是否需要选择时间
    isShowTime: {
      type: Boolean,
      default: false,
    },
    isShowDept: {
      type: Boolean,
      default: true,
    },
    defaultStartDate: {
      type: String,
      default: "",
    },
    defaultEndDate: {
      type: String,
      default: "",
    },
    // 是否显示车间
    showChejian: {
      type: Boolean,
      default: true,
    },

    type: {
      type: String,
      default: "lj",
    },
  },

  computed: {},
  components: { loadMore, rnContentMin, loadingCmp, searchTop },
  methods: {
    // 检索文章
    searchCon(title) {
      this.searchContent = title;
      this.listCon = [];
      this.postBody = {
        title: this.searchContent,
        limit: 10,
        sort: { updated: -1 },
        skip: this.listCon.length,
      };
      this.postUrl = "/services/gttt-wxstats-mgt/search/getSearch";
      this.initData();
    },

    chooseDept() {
      if (this.startDate && this.endDate && this.startDate > this.endDate) {
        this.dailogshow = true;
        this.dailogoptions.content = "日期选择不合法";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }

      let emitData = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      if (this.cidArr.length > 0) {
        emitData["cidArr"] = this.cidArr;
      }
      this.$emit("chooseData", emitData);
    },
    cancel() {
      this.$emit("cancel");
    },

    /****************************** 设置时间 */
    setEndDateValue(date) {
      this.endDate = date;
      this.pickerEndValue = new Date(date);
      // console.log("enddate-----", date, this.startDate, this.endDate);
      if (this.startDate > this.endDate) {
        this.dailogshow = true;
        this.dailogoptions.content = "日期选择不合法";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }

      // 重新获取文章
      this.initData();
    },
    setStartDateValue(date) {
      this.startDate = date;
      this.pickerValue = new Date(date);
      // console.log("startdate-----", date, this.startDate, this.endDate);
      if (this.endDate && this.startDate > this.endDate) {
        this.dailogshow = true;
        this.dailogoptions.content = "日期选择不合法";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }
      // 重新获取文章
      this.initData();
    },

    // 处理文章
    // 转换栏目名
    getColumnStr(obj) {
      let _strArr = [];
      _.map(obj, (v, k) => {
        _strArr[k] = v;
      });
      return _strArr.join(",");
    },
    async initData() {
      // this.loading = true;
      // // 初始化
      // this.dataTotal = 0;
      // this.listCon = [];
      // this.cidArr = [];
      // try {
      //   const result = await wxConPost(this.postUrl, this.postBody);
      //   this.dataTotal = result.count;
      //   if (result.err) {
      //     this.isLoadMore = true;
      //     this.loadMoreShow = true;
      //     this.loading = false;
      //     return;
      //   }
      //   for (const iterator of result.value) {
      //     this.listCon.push({
      //       ...iterator,
      //       checked: false,
      //       clickNumTotal:
      //         iterator.clickNum >= 10000
      //           ? `${
      //               Math.ceil(parseFloat(iterator.clickNum / 1000, 10)) / 10
      //             }万`
      //           : iterator.clickNum,
      //     });
      //   }
      //   if (result.value.length == 0) {
      //     this.loadMoreShow = true;
      //     this.isLoadMore = false;
      //     this.loading = false;
      //     return;
      //   }
      //   this.isLoadMore = true;
      //   this.loadMoreShow = true;
      //   this.loading = false;
      // } catch (error) {
      //   this.loading = false;
      // }
    },

    // loadMore
    async onRefresh() {
      console.log("当前数据长度----", this.listCon.length);
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        const result = await wxConPost(this.postUrl, this.postBody);
        this.dataTotal = result.count;
        for (const iterator of result.value) {
          this.listCon.push({
            ...iterator,
            checked: false,
            clickNumTotal:
              iterator.clickNum >= 10000
                ? `${
                    Math.ceil(parseFloat(iterator.clickNum / 1000, 10)) / 10
                  }万`
                : iterator.clickNum,
          });
        }

        if (result.value.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
          this.loading = false;
          return;
        }

        // 显示加载
        this.isLoadMore = true;
        this.loadMoreShow = true;
        this.loading = false;
      } catch (error) {
        console.log("onRefresh---", error);
      }
    },

    // 选择文章
    chooseItem(cidArr) {
      this.cidArr = cidArr;
    },
  },

  async created() {
    // 若需日期

    // 初始化开始日期
    if (this.defaultStartDate) {
      this.startDate = moment(this.defaultStartDate).format("YYYY-MM-DD");
      this.pickerValue = new Date(this.startDate);
    } else {
      this.startDate = moment(new Date(this.pickerValue)).format("YYYY-MM-DD");
    }

    // 初始化结束日期
    if (this.defaultEndDate) {
      this.endDate = moment(this.defaultEndDate).format("YYYY-MM-DD");
      this.pickerEndValue = new Date(this.endDate);
    } else {
      this.endDate = moment(new Date(this.pickerEndValue)).format("YYYY-MM-DD");
    }

    // 验证查询权限
    const userPm = getUserPm();
    if (userPm && userPm.pmSuper) {
      this.postBody = {
        skip: this.listCon.length,
        limit: 10,
        sort: { updated: -1 },
      };
      this.showSuper = true;
    }
    // this.initData();
  },
  watch: {
    showModal: function (val) {
      this.showChooseDept = this.showModal;
    },
  },
  beforeDestroy() {
    this.showChooseDept = false;
  },
};
</script>
