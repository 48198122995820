<template>
  <div class="main">
    <common-top
      title="趋势分析"
      :needEmit="true"
      @back="back"
      @search="showModal = true"
      :isShowSearch="true"
    ></common-top>

    <loading-cmp v-if="showLoad"></loading-cmp>

    <div v-if="isUserPm">
      <desc-com :startDate="startDate" :endDate="endDate"></desc-com>

      <!-- 选择日期 -->
      <date-choose-com
        :defaultEndDate="endDate"
        :defaultStartDate="startDate"
        :showModal="showModal"
        @chooseData="chooseData"
        @cancel="showModal = false"
      ></date-choose-com>

      <div class="scrollDiv">
        <!-- 统计数据 -->
        <div style="margin-top: 10px">
          <pie-chart
            :chartSytle="`width:98vw; height: 300px`"
            ref="countRef"
            id="countRefId"
            :option="optionCount"
          ></pie-chart>
        </div>
        <div style="margin-top: 10px">
          <pie-chart
            :chartSytle="`width:98vw; height: 300px`"
            ref="likeRef"
            id="likeRefId"
            :option="optionLike"
          ></pie-chart>
        </div>
        <div style="margin-top: 10px">
          <pie-chart
            :chartSytle="`width:98vw; height: 300px`"
            ref="shareRef"
            id="shareRefId"
            :option="optionShare"
          ></pie-chart>
        </div>
        <div style="margin-top: 10px">
          <pie-chart
            :chartSytle="`width:98vw; height: 300px`"
            ref="commentRef"
            id="commentRefId"
            :option="optionComment"
          ></pie-chart>
        </div>
      </div>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
// @ is an alias to /src
import { statsPost, getUserPm } from "../../api/httpApi";
import pieChart from "../../components/pieChart.vue";
import commonTop from "../../components/commonTop.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import noPassCom from "../../components/noPassCom.vue";
import descCom from "../../components/descCom.vue";
import dateChooseCom from "../../components/dateContentListCom.vue";

export default {
  name: "contentStats",
  components: {
    pieChart,
    commonTop,
    loadingCmp,
    dateChooseCom,
    noPassCom,
    descCom,
  },
  data() {
    return {
      loadMoreShow: false,
      isLoadMore: true,

      isUserPm: true, // 是否有权限
      showModal: false, // 是否显示单位
      showContentList: true, // 是否显示 任务列表
      showLoad: false, // 数据加载
      deptData: [],
      isShowSearch: true,
      deptType: "单位",
      searchData: {},
      contentInfo: {},
      startDate: "",
      endDate: moment().format("YYYY-MM-DD"),
      cidArr: [],

      deptIdArr: [], // 选择部门
      deptLevel: 2, // 部门层级

      deptLeaderData: [],
      deptShujiData: [],
      deptPoliticalData: [],

      title: "deptName",

      chartCountData: [],
      chartLikeData: [],
      chartCommentData: [],
      chartShareData: [],

      optionCount: {},
      optionLike: {},
      optionShare: {},
      optionComment: {},

      chartXAxis: [],
    };
  },
  methods: {
    chooseData(emitData) {
      this.showModal = false;
      this.startDate = emitData.startDate;
      this.endDate = emitData.endDate;
      this.cidArr = emitData.cidArr || [];
      this.getContetData();
    },

    back() {
      this.$router.push({ path: "/statsmain" });
    },

    async getContetData() {
      try {
        this.showLoad = true;
        const _query = {
          startDate: this.startDate,
          endDate: this.endDate,
        };
        if (this.cidArr.length > 0) {
          _query["_cidArr"] = this.cidArr;
        }

        const ret = await statsPost("/content/timeSlice", _query);
        if (ret.errMsg) {
          this.$Message.info(ret.errMsg);
        }

        const contentData = ret.ret ? ret.ret : [];
        this.dealData(contentData);
        console.log("contentData------", contentData);
        this.showLoad = false;
      } catch (error) {
        console.log("----", error);
      }
    },
    dataClear() {
      this.chartData = [];
    },

    dealData(contentData) {
      // Hm : "00:00"
      // comment : 80
      // commentView: 5
      // count : 2615
      // like: 1613
      // share: 933
      // timeStr:"2022-10-01 00:00"
      this.dataClear();
      // 处理横坐标
      let dateStr = [];
      for (const iterator of contentData) {
        dateStr.push(iterator.timeStr.substring(5, iterator.length));
      }
      this.chartXAxis = dateStr;
      this.optionCount = this._getOpt(
        "阅读趋势图",
        "点击量",
        _.map(contentData, "count"),
        this.chartXAxis
      );

      this.optionLike = this._getOpt(
        "点赞趋势图",
        "点击量",
        _.map(contentData, "like"),
        this.chartXAxis
      );

      this.optionShare = this._getOpt(
        "分享趋势图",
        "分享",
        _.map(contentData, "share"),
        this.chartXAxis
      );

      this.optionComment = this._getOpt(
        "评论趋势图",
        "评论",
        _.map(contentData, "comment"),
        this.chartXAxis
      );

      this.$nextTick(() => {
        this.$refs.likeRef.refreshData();
      });
      this.$nextTick(() => {
        this.$refs.countRef.refreshData();
      });
      this.$nextTick(() => {
        this.$refs.shareRef.refreshData();
      });
      this.$nextTick(() => {
        this.$refs.commentRef.refreshData();
      });
    },
    // 生成opt
    _getOpt(title, seriesName, seriesData, xAxis) {
      return {
        title: {
          left: "center",
          text: title,
        },
        tooltip: {
          trigger: "axis",
        },

        grid: {
          left: "1%",
          right: "3%",
          bottom: "1%",
          containLabel: true,
        },
        toolbox: {
          show: false,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
          },
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: xAxis,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            name: seriesName,
            type: "line",
            data: seriesData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "平均值" }],
            },
          },
        ],
      };
    },
  },
  async created() {
    // 初始化日期，若时间超过中午，则去当天，否则，取昨天的数据
    this.startDate = moment().subtract(1, "day").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");
    const curHour = moment().format("HH");
    if (parseInt(curHour) >= 12) {
      this.startDate = moment().format("YYYY-MM-DD");
    }

    // this.startDate = "2022-10-01";
    // this.endDate = "2022-10-02";

    // this.startDate = "2022-09-01";
    // this.endDate = "2022-09-02";

    const userPm = getUserPm();
    if (userPm && userPm.pmSuper) {
      this.isUserPm = true;
      this.getContetData();
    } else {
      this.isUserPm = false;
    }
  },
};
</script>

<style scoped></style>
