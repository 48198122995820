<style scoped>
.contentItem {
  text-align: center;
  background: white;
  width: 100%;
  /* box-shadow: 0 1px 3px rgba(105, 93, 93, 0.2); */
  padding: 3px 2px;
  box-shadow: 0px 3px 3px #1e9bcc0d;
}
.contentItem .contentTitle {
  text-align: left;
  width: 100%;
  font-size: 1rem;
}
.contentItem .contentConMin {
  width: 100%;
  color: #a19393;
  text-align: left;
  font-size: 0.8rem;
  display: flex;
}

.deptlistItem {
  text-align: right;
}

.active {
  color: #2876f6;
  font-weight: bold;
}

.deptDeptAll {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
::-webkit-scrollbar {
  width: 0;
}

.showDetail {
  background: white;
  color: white;
  text-align: center;
  position: absolute;
  left: 25px;
  top: -3px;
  z-index: 999;
}

.showDetail .show,
.showDetail .hide {
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}
.showDetail .show {
  display: block;
  max-height: 9999px;
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  animation: showAnimation 0.5s ease-in-out;
  -moz-animation: showAnimation 0.5s ease-in-out;
  -webkit-animation: showAnimation 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  -o-transition: max-height 0.5s ease-in-out;
  -webkit-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}
.showDetail .hide {
  max-height: 0;
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  animation: hideAnimation 0.4s ease-out;
  -moz-animation: hideAnimation 0.4s ease-out;
  -webkit-animation: hideAnimation 0.4s ease-out;
  -moz-transition: max-height 0.4s ease-out;
  -o-transition: max-height 0.4s ease-out;
  -webkit-transition: max-height 0.4s ease-out;
  transition: max-height 0.4s ease-out;
}
</style>
<template>
  <div>
    <div style="text-align: left; margin: 10px">
      <div
        style="
          display: flex;
          justify-content: space-between;
          position: relative;
        "
      >
        <div @click="showDetailInfo" style="">
          <svg
            t="1666863303464"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2573"
            width="20"
            height="20"
          >
            <path
              d="M512 120C296 120 120 296 120 512s176 392 392 392 392-176 392-392S728 120 512 120z m0 736c-190.4 0-344-153.6-344-344S321.6 168 512 168 856 321.6 856 512 702.4 856 512 856z"
              p-id="2574"
            ></path>
            <path
              d="M467.2 656h94.4v91.2h-94.4zM624 313.6c-32-22.4-70.4-33.6-118.4-33.6-35.2 0-65.6 8-91.2 24-38.4 25.6-59.2 67.2-62.4 126.4h91.2c0-17.6 4.8-33.6 14.4-49.6 9.6-16 27.2-24 51.2-24 24 0 41.6 6.4 51.2 19.2 9.6 12.8 14.4 27.2 14.4 43.2 0 14.4-4.8 27.2-12.8 38.4-4.8 6.4-11.2 12.8-17.6 19.2l-22.4 17.6C499.2 512 484.8 528 480 540.8s-8 38.4-9.6 73.6h84.8c0-16 1.6-28.8 4.8-36.8 3.2-12.8 11.2-24 24-33.6l22.4-17.6c22.4-17.6 38.4-32 46.4-43.2 12.8-19.2 20.8-41.6 20.8-68.8-3.2-44.8-17.6-76.8-49.6-100.8z"
              p-id="2575"
            ></path>
          </svg>

          <div v-show="showDetailClass == 'show'" class="showDetail">
            <div
              :class="showDetailClass"
              style="font-size: 12px; color: red; padding: 5px 0"
            >
              <span>
                说明: 全选文章为当前可见列表的文章，非全部的文章;
                不选择文章默认选择全部
              </span>
            </div>
          </div>
        </div>

        <div style="color: #ccc" v-if="curDataLength || dataTotal">
          {{ `选择文章${curDataLength}/${dataTotal}` }}
        </div>
        <div
          :class="`deptlistItem ${allcheck ? 'active' : ''}`"
          @click="clickHandler()"
        >
          <span class="active" v-if="cidArr.length > 0">
            {{ cidArr.length }} /</span
          >全选
          <input :checked="allcheck" type="checkbox" />
        </div>
      </div>
      <div style="height: 400px; overflow: scroll">
        <div v-for="(item, index) in listCon" :key="index" class="contentItem">
          <div>
            <div class="contentTitle">{{ item.title }}</div>
            <div
              style="
                display: flex;
                padding: 8px 0;
                justify-content: space-between;
              "
            >
              <div style="flex: 1" class="contentConMin">
                <!-- <span style="color: red">{{ index + 1 }}</span> -->
                <div>
                  {{ item.name }}
                </div>
                <div v-if="item.clickNum || item.likeNum || item.column">
                  <span style="color: #2f639a" v-if="item.clickNumTotal">
                    {{ item.clickNumTotal }}阅读
                  </span>
                  <span style="color: #515a6e" v-if="item.likeNum">
                    &nbsp; {{ item.likeNum }}点赞
                  </span>

                  <span style="color: #866060" v-if="item.column">
                    ({{ item.column[0] }})
                  </span>
                </div>
              </div>
              <!-- @click="chooseItem(item)" -->
              <div
                :class="`deptlistItem ${allcheck ? 'active' : ''}`"
                @click="clickHandler(item, index)"
              >
                <input
                  v-model="item.checked"
                  :id="`check${index}`"
                  :ref="`check${index}`"
                  :checked="item.checked"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rnContent",
  data() {
    return {
      allcheck: false,
      showDetailClass: "hide",
      cidArr: [],
    };
  },
  props: {
    // 内容数据
    listCon: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 视频展示结构 左右/上下
    layoutStyle: {
      type: String,
      default: "toLeft",
    },
    refreshDataStatus: {
      type: Boolean,
      default: false,
    },
    curDataLength: {
      type: Number,
      default: 0,
    },
    dataTotal: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    showDetailInfo() {
      // 下拉展开
      if (this.showDetailClass == "show") {
        this.showDetailClass = "hide";
      } else {
        this.showDetailClass = "show";
      }
      setTimeout(() => {
        this.showDetailClass = "hide";
      }, 5000);
    },
    chooseItem(data) {
      this.$emit("chooseItem", data);
    },
    clickHandler(item, key) {
      const curchoose = item ? item : "allcheck";
      this.cidArr = [];
      // 若点击全选
      if (curchoose == "allcheck") {
        this.allcheck = !this.allcheck;
        if (this.allcheck) {
          for (const [index, iterator] of this.listCon.entries()) {
            iterator["checked"] = true;
            this.$refs[`check${index}`][0].checked = true;
          }
          this.cidArr = _.map(this.listCon, "_cid"); // 全选当前可见文章的全部数据
        } else {
          for (const [index, iterator] of this.listCon.entries()) {
            iterator["checked"] = false;
            this.$refs[`check${index}`][0].checked = false;
          }
          this.cidArr = []; // 清空选项，默认全选
        }
      } else {
        let _tmpallcheckStatus = true;
        this.listCon[key].checked = this.$refs[`check${key}`][0].checked;
        for (let [index, iterator] of this.listCon.entries()) {
          // 全选/非权限
          if (!this.$refs[`check${index}`][0].checked) {
            if (_tmpallcheckStatus) {
              // 判定是否全选状态，当已经有非选中项，则不重新赋值
              _tmpallcheckStatus = false;
            }
          } else {
            this.cidArr.push(iterator._cid); // 记录选中文章信息
          }
        }

        this.allcheck = _tmpallcheckStatus;
      }

      this.$emit("chooseItem", this.cidArr);
    },
    clearChoose() {
      this.allcheck = false;
      this.cidArr = [];
      for (const [index, iterator] of this.listCon.entries()) {
        iterator["checked"] = false;
        this.$refs[`check${index}`][0].checked = false;
      }
      this.cidArr = []; // 清空选项，默认全选
      this.$emit("chooseItem", this.cidArr);
    },
  },

  watch: {
    refreshDataStatus: function (val) {
      // console.log("refreshDataStatus++++----", this.refreshDataStatus);
      this.clearChoose();
    },
  },
};
</script>
